import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Container, Stack, Typography, Divider, Alert } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { useSelector } from 'src/store';

// ----------------------------------------------------------------------

const ProfileView = () => {
  const settings = useSettingsContext();
  const { user } = useSelector((state) => state.auth);

  const [errorMsg, setErrorMsg] = useState('');

  const UserDataSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    name: Yup.string().required('Name required'),
  });

  const defaultValues = {
    name: '',
    email: ''
  };

  const methods = useForm({
    resolver: yupResolver(UserDataSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      // const response = await register(data);
      // console.log(response, "response")
      // if (response?.data) enqueueSnackbar("Registration successful", { variant: 'success' });
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <RHFTextField name="name" label="Full Name" value={user.name} disabled />

        <RHFTextField name="email" label="Email" value={user.email} disabled />

        {/* <RHFTextField name="balance" label="Balance" value={fNumber(user.balance || 0)} disabled />

        <RHFTextField name="bonus" label="Bonus" value={fNumber((user?.bonus || 0) + (user?.matchBonus || 0))} disabled />

        <RHFTextField name="wagerAmount" label="Wager Requirement" value={fNumber(user.wagerAmount || 0)} disabled /> */}

        <RHFTextField name="phoneNumber" label="Phone Number" value={user.mobile} disabled />

        <RHFTextField name="address" label="Address" value={user.address} disabled />

        {/* <LoadingButton
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Save
        </LoadingButton> */}
      </Stack>
    </FormProvider>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack
        direction="column"
        gap={0.5}
        sx={{
          p: 1,
          mt: 5,
          mb: 1,
          width: 1,
          borderRadius: 2,
          backgroundColor: "#fff",
          boxShadow: "0 .25rem .5rem rgba(0,0,0,.06),0 .125rem 0 rgba(0,0,0,.05)"
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" my={1}>Personal Detail</Typography>
        </Stack>

        <Divider sx={{ mb: 1 }} />

        {renderForm}
      </Stack>
    </Container >
  );
}

export default ProfileView;