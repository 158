import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Container, Stack, Typography, Box, Collapse, Button, Alert } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';
import { getNotifications, checkNotification } from 'src/api/notifications';
import { useDispatch, useSelector } from 'src/store';
import { checkedNotification } from 'src/store/reducers/notification';
import { NotificationType } from 'src/types';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

const NotificationsView = () => {
    const settings = useSettingsContext();

    const { count }: { count: number } = useSelector((state) => state.notification);

    const [notifications, setNotifications] = useState<NotificationType[]>([]);

    const getNotificationData = async () => {
        const response = await getNotifications();

        if (response.status) {
            setNotifications(response.data);
        } else {
            setNotifications([]);
        }
    };

    useEffect(() => {
        getNotificationData();
    }, [count]);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            {
                notifications.length > 0 ?
                    <Stack
                        direction="column"
                        gap={0.5}
                        sx={{
                            p: 1,
                            mt: 5,
                            mb: 1,
                            width: 1,
                            borderRadius: 2,
                            backgroundColor: "#fff",
                            boxShadow: "0 .25rem .5rem rgba(0,0,0,.06),0 .125rem 0 rgba(0,0,0,.05)"
                        }}
                    >
                        {notifications.map((notification: NotificationType, index: number) =>
                            <NotificationDetailView key={index} notification={notification} />
                        )}
                    </Stack>
                    :
                    <Alert severity="info" sx={{ mb: 3, mt: 1 }}>
                        There is no Notification
                    </Alert>
            }
        </Container >
    );
};

const NotificationDetailView = ({ notification }: { notification: NotificationType }) => {
    const dispatch = useDispatch();
    const collapse = useBoolean();

    const onCheckNotification = async () => {
        const response = await checkNotification({ notificationId: notification._id });
        if (response.status) {
            dispatch(checkedNotification());
        }
    };

    const renderCollapse = (
        <Collapse
            in={collapse.value}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: 'white' }}
        >
            <Stack sx={{ m: 1.5 }}>
                <Box
                    component="div"
                    width={1}
                    sx={{
                        whiteSpace: 'pre-wrap',
                        padding: '8px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#f5f5f5',
                        fontFamily: 'monospace',
                    }}
                >{notification.content}</Box>

                <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={onCheckNotification}>Claim</Button>
            </Stack>
        </Collapse>
    );

    return (
        <Stack
            sx={{
                width: 1,
                mt: 1,
                borderRadius: 2,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                boxShadow: (theme) => `${theme.customShadows.z4}`,
                cursor: "pointer",
                overflow: "hidden",
                '&:hover': {
                    border: (theme) => `.125rem solid ${alpha(theme.palette.primary.main, 0.5)}`,
                    boxShadow: (theme) => `0 0 0 .25rem ${alpha(theme.palette.primary.main, 0.1)}`
                }
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width: 1,
                    height: 60,
                    cursor: "pointer",
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
                }}
            >
                <Stack alignItems="center" position="relative">
                    <Iconify icon="mdi:bell-badge" width={30} m={0.5} color="#ffcb14" />
                </Stack>

                <Stack gap={1} flexGrow={1} ml={0.5}>
                    <Typography variant='h6' fontWeight={700}>{notification.title}</Typography>
                </Stack>

                <Stack direction="row" gap={1} mr={1}>
                    <Button
                        variant="text"
                        sx={{
                            fontWeight: "500",
                            '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.16), color: 'primary.darker' }
                        }}
                        onClick={collapse.onToggle}
                    >{collapse.value ? "Read less" : "Read more"}</Button>

                    <Button
                        variant="soft"
                        sx={{
                            fontWeight: "500",
                            '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 1), color: 'white' }
                        }}
                        onClick={onCheckNotification}
                    >Claim</Button>
                </Stack>
            </Stack>

            {renderCollapse}
        </Stack>
    )
};

export default NotificationsView;