import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, Button } from '@mui/material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { useResponsive } from 'src/hooks/use-responsive';
import { useSelector, useDispatch } from 'src/store';
import { remove } from 'src/store/reducers/drawer';

import AccountView, { ProfileView, PasswordChangeView } from 'src/sections/account';
import CashierView, { TransactionView } from 'src/sections/cashier';
import RewardsView, { BonusesView } from 'src/sections/rewards';
import NotificationsView from 'src/sections/notifications';
import AboutUsView from 'src/sections/about-us';

// theme
import { paper } from 'src/theme/css';
import { useSettingsContext } from 'src/components/settings';
//
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';

const MainDrawer = () => {
  const theme = useTheme();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { name } = useSelector((state) => state.drawer);
  const dispatch = useDispatch();

  const [componentToRender, setComponentToRender] = useState<JSX.Element>();
  const [editPage, setEditPage] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const settings = useSettingsContext();
  const isMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    if (name) setOpenDrawer(true);
    setEditPage('');
  }, [name]);

  const onCloseDrawer = () => {
    dispatch(remove());
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (name === 'account') {
      if (editPage === 'profile') {
        setComponentToRender(<ProfileView />);
      } else if (editPage === 'password') {
        setComponentToRender(<PasswordChangeView />);
      } else {
        setComponentToRender(<AccountView setEditPage={(value: string) => setEditPage(value)} />);
      }
    } else if (name === 'cashier') {
      if (editPage === 'transactions') {
        setComponentToRender(<TransactionView />);
      } else {
        setComponentToRender(<CashierView setEditPage={(value: string) => setEditPage(value)} />);
      }
    } else if (name === 'rewards') {
      if (editPage === 'bonuses') {
        setComponentToRender(<BonusesView />);
      } else {
        setComponentToRender(<RewardsView setEditPage={(value: string) => setEditPage(value)} />);
      }
    } else if (name === 'notifications') {
      setComponentToRender(<NotificationsView />);
    } else if (name === 'about us') {
      setComponentToRender(<AboutUsView />);
    } else {
      setComponentToRender(<Box />);
    }
  }, [name, editPage]);

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, px: 2.5 }}
    >
      {
        editPage &&
        <Button
          startIcon={<Iconify icon="bx:arrow-back" />}
          onClick={() => { setEditPage('') }}
          sx={{
            position: "absolute",
            '&:hover': {
              color: "#4471e3",
              backgroundColor: "#e6ecfb"
            }
          }}
        >
          Back
        </Button>
      }

      <Typography variant="h6" sx={{ flexGrow: 1, textTransform: "uppercase", textAlign: "center" }}>
        {name}
      </Typography>

      <Button
        endIcon={<Iconify icon="mingcute:close-line" />}
        onClick={onCloseDrawer}
        sx={{
          position: "absolute",
          right: "20px",
          '&:hover': {
            color: "#4471e3",
            backgroundColor: "#e6ecfb"
          }
        }}
      >
        Close
      </Button>
    </Stack>
  );

  return (
    <Drawer
      anchor="left"
      open={openDrawer && isLoggedIn}
      // onClose={onCloseDrawer}
      slotProps={{
        backdrop: { invisible: false },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 600,
          maxWidth: 1,
          left: { md: (isMini && lgUp ? 88 : 320) },
          backgroundColor: "rgb(237 237 238)"
        },
      }}
    >
      {renderHead}

      {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

      <Scrollbar>
        {componentToRender}
      </Scrollbar>

      {/* <FullScreenOption /> */}
    </Drawer>
  );
}

export default MainDrawer;