import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  name: string;
};

const initialState: initialStateType = {
  name: ''
};

const drawer = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    set(state, action) {
      state.name = action.payload;
    },

    update(state, action) {
      state.name = action.payload ? action.payload : '';
    },

    remove(state) {
      state.name = '';
    },
  },
});

export default drawer.reducer;

export const { set, update, remove } = drawer.actions;
