import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Container, Stack, Typography, Divider, Button, Collapse } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify/iconify';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';

import { getBonuses } from 'src/api/rewards';

import { BonusRuleType } from 'src/types';
// ----------------------------------------------------------------------

const BonusesView = () => {
    const settings = useSettingsContext();

    const [bonuses, setBonuses] = useState<BonusRuleType[]>([]);

    const getBonusData = async () => {
        const response = await getBonuses();

        if (response.status) {
            setBonuses(response.data);
        }
    };

    useEffect(() => {
        getBonusData();
    }, []);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ pb: 2 }}>
            <Stack
                sx={{
                    p: 1,
                    mt: 5,
                    width: 1,
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h5" my={1}>Choose Bonus</Typography>
            </Stack>

            <Divider sx={{ mb: 1 }} />

            {bonuses.length > 0 && bonuses.map((bonus: BonusRuleType, index: number) =>
                <BonusDetailView key={index} bonus={bonus} />
            )}
        </Container >
    );
}

const BonusDetailView = ({ bonus }: { bonus: BonusRuleType }) => {
    const collapse = useBoolean();

    const renderDivider = (
        <Box sx={{
            backgroundImage: "repeating-linear-gradient(90deg,#ededee,#ededee calc(1rem / 4),transparent calc(1rem / 4),transparent calc(1rem / 2))",
            height: 2,
            ml: 6
        }} />
    );

    const renderCollapse = (
        <Collapse
            in={collapse.value}
            timeout="auto"
            unmountOnExit
            sx={{ bgcolor: 'background.neutral' }}
        >
            <Stack sx={{ m: 1.5 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    height={55}
                >
                    <Iconify icon="mdi:minus-box-outline" width={24} m={1.5} />

                    <Stack direction="row" flexGrow={1} gap={1}>
                        <Typography variant='body1' fontWeight={600} width={1}>Min. Deposit</Typography>
                        <Typography variant='body1' fontWeight={600} width={1}>${bonus.min}</Typography>
                    </Stack>
                </Stack>

                {renderDivider}

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    height={55}
                >
                    <Iconify icon="mdi:plus-box-outline" width={24} m={1.5} />

                    <Stack direction="row" flexGrow={1} gap={1}>
                        <Typography variant='body1' fontWeight={600} width={1}>Max. Deposit</Typography>
                        <Typography variant='body1' fontWeight={600} width={1}>${bonus.max}</Typography>
                    </Stack>
                </Stack>

                {renderDivider}

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    height={55}
                >
                    <Iconify icon="codicon:gift" width={24} m={1.5} />

                    <Stack direction="row" flexGrow={1} gap={1}>
                        <Typography variant='body1' fontWeight={600} width={1}>Bonus</Typography>
                        <Typography variant='body1' fontWeight={600} width={1}>%{bonus.bonus}</Typography>
                    </Stack>
                </Stack>

                {renderDivider}

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    height={55}
                >
                    <Iconify icon="ic:round-autorenew" width={24} m={1.5} />

                    <Stack direction="row" flexGrow={1} gap={1}>
                        <Typography variant='body1' fontWeight={600} width={1}>Wager (deposit + bonus)</Typography>
                        <Typography variant='body1' fontWeight={600} width={1}>X{bonus.wager}</Typography>
                    </Stack>
                </Stack>

                {renderDivider}

                {/* <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                    height={55}
                >
                    <Iconify icon="majesticons:calendar" width={24} m={1.5} />

                    <Stack direction="row" flexGrow={1} gap={1}>
                        <Typography variant='body1' fontWeight={600} width={1}>Duration (days)</Typography>
                        <Typography variant='body1' fontWeight={600} width={1}>3</Typography>
                    </Stack>
                </Stack>

                {renderDivider} */}

                <Button variant="contained" color="primary" sx={{ mt: 2 }}>Claim</Button>
                {/* <Button
                    variant="text"
                    color="primary"
                    endIcon={<Iconify icon="material-symbols-light:content-copy" />}
                    sx={{ mt: 1 }}
                >Bonus Terms apply</Button> */}
            </Stack>
        </Collapse>
    );

    return (
        <Stack
            sx={{
                width: 1,
                mt: 1,
                borderRadius: 2,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                boxShadow: (theme) => `${theme.customShadows.z4}`,
                cursor: "pointer",
                overflow: "hidden",
                '&:hover': {
                    border: (theme) => `.125rem solid ${alpha(theme.palette.primary.main, 0.5)}`,
                    boxShadow: (theme) => `0 0 0 .25rem ${alpha(theme.palette.primary.main, 0.1)}`
                }
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width: 1,
                    cursor: "pointer",
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
                }}
            >
                <Stack alignItems="center" position="relative">
                    <Iconify icon="mingcute:hexagon-fill" width={64} m={0.5} color="#ffcb14" />
                    <Stack alignItems="center" justifyContent="center" position="absolute" width={1} height={1}>
                        <Typography variant="h5" fontWeight={800} color="#856241">%</Typography>
                    </Stack>
                </Stack>

                <Stack direction="column" gap={1} flexGrow={1}>
                    <Typography variant='body1' fontWeight={700}>{bonus.name}</Typography>
                    <Typography variant='body2'>{bonus.name}</Typography>
                </Stack>

                <Stack direction="row" gap={1} mr={1}>
                    <Button
                        variant="text"
                        sx={{
                            fontWeight: "500",
                            '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.16), color: 'primary.darker' }
                        }}
                        onClick={collapse.onToggle}
                    >{collapse.value ? "Read less" : "Read more"}</Button>

                    <Button
                        variant="soft"
                        sx={{
                            fontWeight: "500",
                            '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 1), color: 'white' }
                        }}
                    >Claim</Button>
                </Stack>
            </Stack>

            {renderCollapse}
        </Stack>
    )
};

export default BonusesView;