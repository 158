// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------
export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const API_PATH = {
  // auth
  AUTH_ROOT: 'auth',
  AUTH_REGISTER: 'auth/user-register',
  AUTH_LOGIN: 'auth/user-login',
  AUTH_CHECK: 'auth/check-auth',
  // game
  GAME_GET_TOP_LIST: 'game/get-top-game-list',
  GAME_GET_TOP_CATEGORY_LIST: 'game/get-top-category-game-list',
  GAME_GET_LIST: 'game/get-game-list',
  GAME_ACCESS_URL: 'game/get-access-url',
  PROVIDER_GET_LIST: 'game/get-provider-list',
  WIN_DATA_GET: 'game/get-win-data',
  CATEGORIES_GET: 'game/get-categories',
  // cashier
  TRANSACTION_HISTORY_GET: 'cashier/get-transaction-history',
  BET_HISTORY_GET: 'cashier/get-bet-history',
  // rewards
  BONUS_GET: 'rewards/get-bonuses',
  // notifications
  NOTIFICATION_GET: 'notifications/get-notifications',
  NOTIFICATION_CHECK: 'notifications/check-notification',

  // user
  USER_GET_DATA: 'user/get-user-data',
  USER_BET_HISTORY: 'user/get-bet-history',
};

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = `${paths.dashboard.root}/`; // as '/'
export const PATH_LOGIN = paths.auth.login; // as '/auth/login'
